import React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import theme from '../theme';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link } from 'gatsby';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  minHeight: '100vh',
  overflow: 'hidden',
  backgroundColor: '#fff',
  justifyContent: 'center',
  scrollSnapType: 'y mandatory',
  scrollBehavior: 'smooth',
}));

const ScrollAnchor = styled(Box)({
  position: 'absolute',
  scrollSnapAlign: 'start',
  scrollSnapStop: 'always',
});

const ContentGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.background.default,
  padding: theme.spacing(5),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& b': {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '3rem',
  marginBottom: theme.spacing(2),
}));

const SectionContent = ({ image, signPost }) => {
  return (
    <StyledBox>
      <ScrollAnchor id='Aleignment' sx={{ top: -70 }} />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: '#fff',
          img: {
            height: '100%',
            margin: 0,
            maxWidth: 'none',
            padding: 0,
            position: 'absolute',
            right: 0,
            width: '100%', // Full width for all screen sizes to accommodate landscape image
            objectFit: 'cover',
            zIndex: 0, // Ensure image is behind the gradient and content
          },
        }}
      >
        <Box
          sx={{
            background:
              'linear-gradient(to left, rgba(255,255,255,0) 30%, rgba(255,255,255,0.9) 60%, rgba(255,255,255,1) 80%)',
            bottom: 0,
            height: '100%',
            left: 0, // Start from the left edge for full-width coverage
            position: 'absolute',
            width: '100%', // Cover the entire width
            MozBackgroundSize: '100% 100%',
            OBackgroundSize: '100% 100%',
            WebkitBackgroundSize: '100% 100%',
            backgroundSize: '100% 100%',
            zIndex: 1,
            display: { xs: 'block', md: 'block' }, // Show on all screen sizes
          }}
        />
        <img src={signPost} alt='ALEIGN Instructions' />
      </Box>
      <Grid container justify='space-between' sx={{ zIndex: '2' }}> {/* Increased z-index to ensure content is above gradient */}
        <Grid
          item
          xs={12}
          md={7}
          variant='content'
          sx={{ color: theme.palette.background.default }}
        >
          <Box sx={{ padding: '0 70px' }}>
            <Typography
              variant='h2'
              color='primary'
              sx={{
                textTransform: 'none',
                fontWeight: '700',
                fontSize: '3rem',
                // wordBreak: 'break-word',
              }}
            >
              Beyond Roadmaps
            </Typography>
            <Typography variant='h5' my={2} mb={4} color='primary'>
              ALEiGN's AI-Powered ICT Transformation.
            </Typography>
            <Typography variant='body1'>
              When ICT systems fail to meet your needs, ALEiGN offers a{' '}
              <b>unique approach.</b> We blend <b>seasoned human consultants</b> with
              advanced <b>AI Agents</b> to provide a comprehensive analysis of your
              organisation's strategy, processes, and technology.
            </Typography>
            <Typography variant='body1'>
              We uncover the root causes of your ICT challenges, ensuring solutions are
              not only cost-effective but fully aligned with your strategic objectives.{' '}
            </Typography>
            <Typography variant='body1'>
              Experience a deeper level of insight and a more successful transformation
              with ALEiGN.
            </Typography>

            <Typography
              variant='h3'
              mb={2}
              mt={4}
              sx={{ fontWeight: 700, textTransform: 'none' }}
              color='secondary'
            >
              #context #comms #culture #move2done!
            </Typography>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent='center'
              alignItems='center'
              spacing={3}
              mt={4}
              pb={4}
            >
              <Button
                variant='aleign'
                component={Link}
                to='#AboutV2'
                sx={{
                  color: theme.palette.primary.main,
                  '&:after, &:before': { background: theme.palette.primary.main },
                  '&:hover,&:focus,&:active,&.active': {
                    color: '#fff',
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                  },
                }}
              >
                Read on
              </Button>
              <Button
                variant='aleign'
                // component={Link}
                // to='#'
                onClick={() => window.FreshworksWidget('open')}
                sx={{
                  color: theme.palette.primary.main,
                  '&:after, &:before': { background: theme.palette.primary.main },
                  '&:hover,&:focus,&:active,&.active': {
                    color: '#fff',
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                  },
                }}
                rel='noopener noreferrer'
              >
                I have a question
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default SectionContent;
