import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import theme from '../theme';

const Section = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1,
  top: 64,
});

const SolutionCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: 0,
  ':after': {
    position: 'absolute',
    display: 'block',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    content: '" "',
    top: '10px',
    left: '10px',
    border: '4px solid rgba(255, 255, 255, 0.45)',
    zIndex: 1,
    transition: 'border-color 0.5s ease',
  },
  '.MuiCardHeader-root': {
    transition: 'all 2s ease',
    p: 0,
    width: '100%',
    '.MuiCardHeader-avatar': { 
      width: '100%' 
    },
    '.gatsby-image-wrapper': {
      width: '100%',
      borderRadius: 0,
      zIndex: 0,
    },
  },
  '.MuiCardContent-root': {
    padding: theme.spacing(5),
    position: 'relative',
    zIndex: 2
  },
  '.footer': {
    position: 'relative',
    zIndex: 2
  },
  '.filter': {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0, 0.5)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center;',
    zIndex: '-1',
    transition: 'all 0.5s ease',
  },
  '&:hover': {
    transition: 'all 0.5s ease',
    color: theme.palette.primary.main,
    '& .filter': {
      backgroundColor: 'rgba(0,0,0, 0.1)',
      transition: 'all 0.5s ease',
      transform: 'scale(1.2)',
    },
    '.MuiCardHeader-root': {
      transition: 'all 2s ease',
    },
    ':after': {
      borderColor: theme.palette.primary.main,
      transition: 'border-color 0.5s ease',
    },
    '& .MuiButton-root.MuiButton-aleign': {
      '&:after, &:before': {
        background: theme.palette.primary.main,
      },
      '&:hover,&:focus,&:active,&.active': {
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '&:after, &:before': { 
          background: theme.palette.primary.main 
        },
      }
    }
  }
}));

const SolutionListTemplate = ({ data }) => {
  const { edges: solutions } = data.allMarkdownRemark;

  return (
    <Section>
      <Box sx={{ width: '100%', maxWidth: 1200, margin: '0 auto', padding: 4 }}>
        <Masonry columns={{ xs: 1, sm: 2 }} spacing={4}>
          {solutions.map(({ node: solution }) => (
            <SolutionCard key={solution.id}>
              {solution.frontmatter.featuredimage && (
                <PreviewCompatibleImage
                  imageInfo={{
                    image: solution.frontmatter.featuredimage,
                    alt: `featured image for ${solution.frontmatter.title}`,
                  }}
                />
              )}
              <CardContent className='content text-center'>
                <Typography textAlign='center' variant='h3'>
                  {solution.frontmatter.title}
                </Typography>
                <Typography textAlign='center' variant='body1' className='description'>
                  {solution.frontmatter.description}
                </Typography>
              </CardContent>
              {solution.excerpt ? (
                <CardActions
                  className='footer text-center'
                  sx={{ display: 'flex', p: 2, pb: 4, pt: 0, justifyContent: 'center' }}
                >
                  <Button
                    variant='aleign'
                    component={Link}
                    to={solution.fields.slug}
                  >
                    Learn More
                  </Button>
                </CardActions>
              ) : null}
              <Box className='filter'></Box>
            </SolutionCard>
          ))}
        </Masonry>
      </Box>
    </Section>
  );
};

SolutionListTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const SolutionList = () => {
  const data = useStaticQuery(graphql`
    query SolutionListQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "solution-post" } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 200)
            id
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              description
              category
              date(formatString: "MMMM DD, YYYY")
              featuredpost
              featuredimage {
                childImageSharp {
                  gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `);

  return <SolutionListTemplate data={data} />;
};

export default SolutionList;
