import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';

import { StaticImage } from 'gatsby-plugin-image';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import theme from '../../theme';

// import separator from '../images/separatorWhite.png';
import distilled from '../../img/Distilled-AS_245013906.jpg';

const Section = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: alpha(theme.palette.background.paper, 0),
	position: 'relative',
	scrollSnapType: 'y mandatory',
	scrollBehavior: 'smooth',
}));

const FrontContent = styled(Box)({
	marginTop: 'auto',
	marginBottom: '50px',
	margin: 'auto',
	// color: '#000',
	zIndex: 1,
});

const ScrollAnchor = styled(Box)({
	position: 'absolute',
	top: '-84px',
	scrollSnapAlign: 'start',
	scrollSnapStop: 'always',
});

function AboutSection() {
	return (
		<Section className='section' px={8}>
			<ScrollAnchor id='mantra' />
			<Box
				sx={{
					// maxHeight: '400px',
					position: 'absolute',
					width: '100%',
					height: '100%',
					top: '0px',
					left: '0px',
					backgroundColor: theme.palette.background.default,
					opacity: '0.2',
					img: {
						height: '100%',
						margin: 0,
						maxWidth: '100%',
						padding: 0,
						position: 'absolute',
						right: '0px',
						top: '0px',
						// width: '60%',
						objectFit: 'cover',
					},
				}}
			>
				{/* <Box
					sx={{
						background:
							'linear-gradient(to left, rgba(255,255,255,0) 30%,rgba(255,255,255,1) 49%,rgba(255,255,255,1) 100%)',
						bottom: 0,
						height: '100%',
						left: 0,
						position: 'absolute',
						width: '80%',
						MozBackgroundSize: '100% 100%',
						OBackgroundSize: '100% 100%',
						WebkitBackgroundSize: '100% 100%',
						backgroundSize: '100% 100%',
						zIndex: 1,
					}}
				/> */}
				<img src={distilled} alt='ALEIGN Distilled' />
			</Box>
			<FrontContent px={8} maxWidth='lg' className='pItem pFnt'>
				<Box className='container' maxWidth='lg' pt={10} pb={14}>
					<Grid container justify='center' spacing={6}>
						<Grid item xs={12}>
							<Typography
								my={2}
								component='h5'
								variant='h5'
								color='primary'
								align='center'
							>
								ALEiGN has distilled countless years
								<br /> of learned and gathered knowledge into
							</Typography>
							<Typography
								variant='h3'
								my={2}
								sx={{ fontWeight: 700, textTransform: 'none', fontSize: '3.6rem' }}
								align='center'
							>
								4 KEY CONCEPTS
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant='h5' my={2} color='primary'>
								#context
							</Typography>
							<Typography pb={1}>
								No channels or siloed information & teams.
							</Typography>
							<Typography pb={1}>
								ALEiGN has a tiered information structure (domains) to make tasks,
								chats, & the dashboard contextual with micro and macro views across the
								domains.
							</Typography>
							<Typography variant='h5' my={2} color='primary'>
								#comms{' '}
							</Typography>
							<Typography variant='body1' my={2}>
								Good communication builds relationships.
							</Typography>
							<Typography pb={1}>
								Contextual chat throughout the environment puts conversations &
								important info right where it should be, not lost in 3rd party comms
								tools or email.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant='h5' my={2} color='primary'>
								#culture
							</Typography>
							{/* <Typography variant='body1' my={2}>
								"Working hard for something we don’t care about is called stress;
								working hard for something we love is called passion." - Simon Sinek
							</Typography> */}
							<Typography pb={1}>
								Passionate culture starts with #context & #comms. Conversations with the
								right people at the right time about the right things.
							</Typography>
							<Typography pb={1}>
								A #moved2done! survey and 'sentiment' graph contextually gives managers
								a view of 'how we’re all going’.
							</Typography>
							<Typography variant='h5' my={2} color='primary'>
								#move2done!
							</Typography>
							<Typography variant='body1' my={2}>
								Execution is key!
							</Typography>
							<Typography pb={1}>
								Decompose your strategy based on your chosen framework. Break this plan
								into executable tasks.
							</Typography>
							<Typography pb={1}>Keep tasks small & #move2done! often.</Typography>
							<Typography pb={1}>
								Replicate, optimize, and continuously improve.
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</FrontContent>
		</Section>
	);
}

export default AboutSection;
