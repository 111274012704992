// copy of templates/index-page.js

import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { alpha } from '@mui/material';
import theme from '../theme';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import HeroSectionTheir from '../components/sections/HeroSectionTheir';
import Mantra from '../components/sections/ALEiGN-mantraV2';
import ALEiGNToolbox from '../components/sections/ALEiGN-Toolbox';
import AboutSection from '../components/AboutSectionV2';
import UpdateList from '../components/UpdateList';
import SolutionList from '../components/SolutionList';
import signPost from '../img/CyberRoadMap.jpeg';
import SectionContent from '../components/SectionContent';

// eslint-disable-next-line
export const ALEIGNintroducingTemplate = ({
	image,
	title,
	heading,
	subheading,
	mainpitch,
	description,
	main,
	intro,
	content,
	contentComponent,
}) => {
	const heroImage = getImage(image) || image;
	const PageContent = contentComponent || Content;

	return (
		<>
			<HeroSectionTheir />
			<SectionContent signPost={signPost} />
			{/* <SolutionList /> */}
			<AboutSection />
			<Mantra />
			<ALEiGNToolbox />
			<UpdateList />
		</>
	);
};

ALEIGNintroducingTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const ALEIGNintroducing = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { markdownRemark: post } = data;

	return (
		<Layout theme={theme}>
			<ALEIGNintroducingTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				mainpitch={frontmatter.mainpitch}
				description={frontmatter.description}
				main={frontmatter.main}
				intro={frontmatter.intro}
				contentComponent={HTMLContent}
				content={post.html}
			/>
		</Layout>
	);
};

ALEIGNintroducing.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ALEIGNintroducing;

export const pageQuery = graphql`
	query introducingTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "aleign-theirPage" } }) {
			html
			frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				subheading
				mainpitch {
					title
					description
				}
				description
				intro {
					blurbs {
						text
						heading
					}
					heading
					description
				}
				main {
					heading
					description
					image1 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
							}
						}
					}
					image2 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
							}
						}
					}
					image3 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(quality: 100, layout: FULL_WIDTH)
							}
						}
					}
				}
			}
		}
	}
`;
