import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';
import theme from '../../theme';

import { StaticImage } from 'gatsby-plugin-image';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';

import toolbox from '../../img/ToolBox-AS_410158036.jpg';

// import separator from '../images/separatorWhite.png';

const SectionContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	zIndex: 1,
	position: 'relative',
	height: '100%',
	overflow: 'hidden',
	scrollSnapType: 'y mandatory',
	scrollBehavior: 'smooth',
});

const ScrollAnchor = styled(Box)({
	position: 'absolute',
	top: '-84px',
	scrollSnapAlign: 'start',
	scrollSnapStop: 'always',
});

const JonesieSection = () => {
	return (
		<SectionContent py={0} px={6} m={0}>
			<ScrollAnchor id='toolBox' sx={{ top: -70 }} />
			<Box
				sx={{
					// maxHeight: '400px',
					position: 'absolute',
					width: '100%',
					height: '100%',
					top: '0px',
					left: '0px',
					backgroundColor: '#fff',
					// opacity: '0.2',
					img: {
						height: '100%',
						margin: 0,
						maxWidth: 'none',
						padding: 0,
						position: 'absolute',
						right: '0px',
						bottom: '0',
						width: '100%',
						objectFit: 'cover',
					},
				}}
			>
				{/* <Box
					sx={{
						background:
							'linear-gradient(to left, rgba(255,255,255,0) 30%,rgba(255,255,255,1) 49%,rgba(255,255,255,1) 100%)',
						bottom: 0,
						height: '100%',
						left: 0,
						position: 'absolute',
						width: '80%',
						MozBackgroundSize: '100% 100%',
						OBackgroundSize: '100% 100%',
						WebkitBackgroundSize: '100% 100%',
						backgroundSize: '100% 100%',
						zIndex: 1,
					}}
				/> */}
				<img src={toolbox} alt='ALEIGN Toolbox' />
			</Box>
			<Grid container justify='space-between' sx={{ zIndex: '1' }}>
				{/* <Grid item xs={0} md={7}></Grid> */}
				<Grid item xs={12} sm={7} sx={{ py: 8, px: { xs: 2, sm: 4, md: 8 } }}>
					<Typography
						variant='h3'
						mb={2}
						sx={{ fontWeight: 700, textTransform: 'none' }}
						color='primary'
					>
						Let's have a peek into the
					</Typography>

					<Typography
						variant='h2'
						color='primary'
						sx={{ fontWeight: 'inherit', wordBreak: 'break-word' }}
					>
						ALEiGN Toolbox
					</Typography>
					<Typography
						color={theme.palette.background.default}
						sx={{ fontWeight: 'inherit' }}
						mt={2}
					>
						Remember, no matter how shinny the hammer is, if the swinging process is
						flawed, thumbs will be bloodied!
					</Typography>
				</Grid>
			</Grid>
		</SectionContent>
	);
};

export default JonesieSection;
